// src/App.js
import React, {useEffect, useState} from 'react';
import KeywordsWidget from './components/KeywordsWidget';
import TagManager from 'react-gtm-module'

// Initialize GTM with your container ID
const tagManagerArgs = {
    gtmId: 'GTM-PR94SGB3'  // Replace 'GTM-XXXXXX' with your actual GTM container ID
};

TagManager.initialize(tagManagerArgs);




function App() {


    return (
        <div className="App">
            <div className="scale-container">
                <div className="scale-content">
                    <KeywordsWidget />
                </div>
            </div>
        </div>
    );
}

export default App;